$(() => {
    $(".fileinput").fileinput({
        //theme: "bs5",
        showUpload: true,
        maxFileSize: 5000,
        dropZoneEnabled: false,
        maxFileCount: 1,
        msgPlaceholder: "Selectionnez un fichier PDF",
        allowedFileTypes: ['pdf'],
        previewFileType: 'any',
    });
});
