//Load packages dependances
require('./bootstrap');

//Initialize packages
require('./select2');
require('./fileInput');

//Custom JS
require('./order');
require('./custom');
require('./tableClickableRow');
