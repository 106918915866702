$(() => {
    //All select2
    $('.select2').select2({
        placeholder: "Selectionner...",
        allowClear: false,
        width: "100%",
        language: {
            noResults: function () {
                return "Aucun resultat trouvé, saisissez autre chose.";
            }
        },
        //allowClear: true,
    });
});
