$(() => {
    let $owner_department_select = $('[data-owner-department]');
    let $owner_city_select = $('[data-owner-city]');
    let $owner_region_select = $('[data-owner-region]');
    let $owner_district_select = $('[data-owner-district]');

    let $place_department_select = $('[data-place-department]');
    let $place_city_select = $('[data-place-city]');
    let $place_region_select = $('[data-place-region]');
    let $place_district_select = $('[data-place-district]');

    let $categorySelect = $('[data-category]');
    let $receiptAmountInput = $('[data-receipt-amount]');

    let $fileSelect = $('[data-file-select]');

    let order = {
        initSelects: ($departmentSelect, $citySelect,
                      $regionSelect, $districtSelect) => {
            $departmentSelect.on('change', () => {
                let department_id = $departmentSelect.val();
                if(department_id) {
                    let cities = order.getCities(department_id);
                    order.emptySelect($citySelect);
                    order.emptySelect($regionSelect);
                    order.emptySelect($districtSelect);
                    order.fillSelect(cities, $citySelect, "Selectionnez une ville");
                }
            }).change();

            $citySelect.on('change', () => {
                let city_id = $citySelect.val();
                if(city_id) {
                    let regions = order.getRegions(city_id);
                    order.emptySelect($regionSelect);
                    order.emptySelect($districtSelect);
                    order.fillSelect(regions, $regionSelect, "Selectionnez un arrondissement");
                }
            }).change();

            $regionSelect.on('change', () => {
                let region_id = $regionSelect.val();
                if(region_id) {
                    let districts = order.getDistricts(region_id);
                    order.emptySelect($districtSelect);
                    order.fillSelect(districts, $districtSelect, "Selectionnez un quartier");
                }
            }).change();
        },
        getCities: (department_id) => {
            console.log(department_id, window.departments);
            let department = window.departments.find(d => d.id == department_id);
            if(department) {
                return department.cities;
            }
            return [];
        },
        getRegions: (city_id) => {
            let city = window.cities.find(c => c.id == city_id);
            if(city) {
                return city.regions;
            }
            return [];
        },
        getDistricts: (region_id) => {
            let region = window.regions.find(r => r.id == region_id);
            if(region) {
                return region.districts;
            }
            return [];
        },
        fillSelect: (data, $select, placeholderText) => {
            $select.empty();
            $select.append(`<option selected="selected" value="">${placeholderText}</option>`);
            data.forEach(s => {
                $select.append('<option value="' + s.id + '">' + s.name + '</option>')
            });
        },
        emptySelect: ($select) => {
            $select.empty();
        },
        fillReceiptAmount: ($categorySelect, $receiptAmountInput) => {
            $categorySelect.on('change', () => {
                let category_id = $categorySelect.val();
                if(category_id) {
                    let category = order.getCategory(category_id);
                    if(category) {
                        $receiptAmountInput.val(category.price)
                    }
                }
            }).change()
        },

        getCategory: (category_id) => {
            return window.categories.find(c => c.id == category_id)
        },

        hideFileModal:() => {
            $fileSelect.on('change', () => {
                let fileFied = $fileSelect.val();
                if(fileFied) {
                    let $fileFied = $(`[${fileFied}]`);
                    $('#filesModal').modal('hide');
                    $fileFied.contents().prependTo('[data-input-container]');
                    $fileFied.remove();
                    delete window.file_types[`${fileFied}`];
                    $fileSelect.empty();
                    $.each(window.file_types, (key, value) => {
                        $fileSelect.append('<option value="' + key + '">' + value + '</option>')
                    });
                }

            })
        },

        init: () => {
            order.initSelects(
                $owner_department_select, $owner_city_select,
                $owner_region_select, $owner_district_select
            );

            order.initSelects(
                $place_department_select, $place_city_select,
                $place_region_select, $place_district_select
            );

            order.fillReceiptAmount($categorySelect, $receiptAmountInput);

            order.hideFileModal();
        },
    };

    order.init();
});
